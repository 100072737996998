<template>
  <div id="app">
    <router-view/>
    <van-tabbar v-model="active">
      <van-tabbar-item icon="home-o" replace to="/" > 作品领取</van-tabbar-item>
      <van-tabbar-item icon="user-o" replace to="/my" >我的</van-tabbar-item>
      <!-- <van-tabbar-item icon="search"> <router-link to="/login">登录</router-link></van-tabbar-item> -->
    </van-tabbar>
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<script>

export default {
  data() {
    return {
      loading: true,
      active:'1',
    };
  },
  mounted() {
  },
  created(){
  
  },
  methods:{
  
  }
};
</script>
