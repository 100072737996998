import axios from 'axios'
import { Notify } from 'vant';
import store from '@/store'

// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
     const res = response.data
      if(res.status == 10004 ) {
          window.location.href = process.env.VUE_APP_BASE_API + '/api/frontend/liunian/wechat_login'
          return Promise.reject(new Error(res.message || 'Error'))
        } else {
          return res
        }
  },
  error => {
    console.log('err' + error) 
    Notify({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
