<template>
  <div class="home">
    <HelloWorld msg="领取作品"/>
  </div>

</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  },
  data() {
    return {
      active: 0,
    };
  },
}
</script>
